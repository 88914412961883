
import {
  createContext, useContext, useMemo, useState, useEffect
} from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from './customAxios';
import { useLogin } from './LoginContext';
import { useNavigate } from 'react-router-dom'
// import useRerenderCount from './useRenderCount';
import { useCachedValueOnLocationChange } from './useCachedValueOnLocationChange';
// useMemo(() => {
// let renderCount = 0;
//  }, []);
// import { getCarriers } from './getCarriers'

const ShopContext = createContext(null)
// const initialState =

const ShopProvider = ({ children }) => {
  // const rerenderCount = useRerenderCount();
  // const location = useLocation();

  const [state, setState] = useState({
    products: [],
    sellers: null,
    sellersSA: null,
    filterinfo: null,
    item: [],
    outOfStock: false,
    sizeName: '',
    sizeID: null,
    // seller: JSON.parse(localStorage.getItem("seller")) || {},
    sellerInfo: null,
    // buyer: JSON.parse(localStorage.getItem("buyer")) || null,
    // token: localStorage.getItem("token") || "",
    name: localStorage.getItem('name') || '',
    username: '',
    // isSeller: JSON.parse((localStorage.getItem('isSeller') || {})),
    //isAdmin: JSON.parse(localStorage.getItem('isAdmin')),
    // isSA: JSON.parse(localStorage.getItem('isSA')),
    //isPath: JSON.parse(localStorage.getItem('isSA')) ? 'buyerSA' : 'buyer',
    brands: null,
    brandsAll: null,
    filter: null,
    brandsMapped: null,
    typeCategories: null,
    toggle: false,
    toggleText: 'Sign in as seller',
    authErrMsg: '',
    stripeKey: '',
    stripePromise: null,

  })
  const value = useMemo(() => ({ state, setState, }), [state])

  //   < div >
  //   <p>Provider Rerender Count: {rerenderCount}</p>
  // { children }
  //     </div >
  return (
    <ShopContext.Provider value={value}>
      {children}
    </ShopContext.Provider>
  );
}

const useShop = () => {
  // renderCount += 1;
  // console.log(`${useShop.name}. renderCount: `, renderCount);
  useEffect(() => {
    if (!state.stripePromise) {
      //   console.log('get stripe again')
      getstripe()
    }
  }, []);

  const { state, setState } = useContext(ShopContext);
  const navigate = useNavigate();
  const { buyer, } = useLogin();
  const isPath = useCachedValueOnLocationChange();
  // const [saPath] = useOutletContext();
  const handleAuthErr = (authErrMsg) => {
    setState((prevInputs) => ({
      ...prevInputs, authErrMsg,
    }))
  }

  // const'Shop Context: ' ?? null;
  const getstripe = async () => {

    try {
      const response = await axios.get('/payment/stripekey/getkey')
      const stripePromise = await loadStripe(response.data.publicKey)
      setState((prevInputs) => ({
        ...prevInputs,
        stripeKey: response.data.publicKey,
        stripePromise,
      }))
      // return response.data
    } catch (e) {
      console.log(e)
    }
  }

  const SAProductsFilter = async (params) => {
    try {
     // console.log('Shop Context: ', params)
      const response = await axios.put(`${isPath}/buyer/filter2`, params)
     // console.log(response.data)
      setState((prevInputs) => ({
        ...prevInputs,
        products: response.data,
      }))
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  // filter-brands
  const SAProductsAndBrands = async (params) => {
    try {
      if (state.brandsAllCounted) {
       // console.log('already got brands!!!!', state.brandsAllCounted)
        return state.brandsAllCounted
      } else {
        const brands = await axios.get(`${isPath}/buyer/filter-brands`)
        // {brandedItems: result, brandsList}
        if (brands.data) {
          // const branddata = brands.data.names.map((category) => ({ value: category, label: category }))
         
          setState((prevInputs) => ({
            ...prevInputs,
            brandsAll: brands.data.brandsList,
            // brandsAllCounted: brands.data.brandedItems
            brandsMapped: {
              brands: brands.data.brandedItems.brands,
              other: {size: brands.data.brandedItems.customBrands}
            }
          }))
         
          return brands.data
        }
      }
      const response = await axios.get(`${isPath}/buyer/filter-brands`, { params })
   //   console.log(response.data)
      setState((prevInputs) => ({
        ...prevInputs,
        products: response.data,
      }))
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

// get the featured items:

  const getFeaturedProductsFilter = async (params) => {
    try {
      // console.log('Shop Context: ', params)
      const response = await axios.put(`${isPath}/buyer/featured`, params)
     console.log(response.data)
      // setState((prevInputs) => ({
      //   ...prevInputs,
      //   products: response.data,
      // }))
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  const getBuyerSellers = async (params) => {
    try {
      // if (state.sellers) {
      //   return state.sellers
      // }
      const response = await axios.get(`${isPath}/buyer/sellers`, { params })
      //  console.log(response)
      setState((prevInputs) => ({
        ...prevInputs,
        sellers: response.data.sellers,
        // itemcount: response.data.items
      }))
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  const fetchsellerProducts = async (id, passSeller) => {
    try {

      const { data: { items, seller, } } = await axios.get(`${isPath}/buyer/selleruser/${id}`)

      setState((prevInputs) => ({
        ...prevInputs,
        sellerInfo: seller,
      }));
      if (passSeller) {
        return {items, seller}
        } else {
      return items
        }
    } catch (err) {
      handleAuthErr(err.response?.data?.message);
      throw new Error(err.response.data.message)
     // console.log(err)
    //  console.log(err.response.status);
    }
  }


  const fetchProduct = async (id) => {
    // increments views, gets the seller and product info.
    try {

      const { data } = await axios.get(`${isPath}/buyer/viewcount/${id}`)
      const { seller, ...rest } = data
      setState((prevInputs) => ({
        ...prevInputs, sellerInfo: seller, item: rest,
      }))
      return  rest; //true; //data.isLive
    } catch (err) {
      console.log(err)
      console.log(err.response.status);
      if (err.response.status === 404) {
      
        navigate('/');
        window.location.href = '/';
        // browserHistory.push("/my404page");
        return true;
      }
    }
  }

  const checkInStock = async (id, sizeID) => { // moved to checkoutform
    //  let soldout = false
    try {
      const { data } = await axios.put(`${isPath}/buyer/checkinstock/${id}`, { sizeID })
      // console.log("soldout", data.soldout)
      setState((prevInputs) => ({
        ...prevInputs, sizeID, outOfStock: data.soldout, sizeName: data.sizeName,
      }))
      // return data.soldout;
    } catch (e) {
      console.log(e)
    }
  }
  const checkanddecrement = async (id, sizeID) => {
    // try {

    const { data } = await axios.put(`${isPath}/buyer/checkout/${id}`, { sizeID })
   // console.log('called multple times!,', data);
    // console.log(state.outOfStock, data)
    if (state.outOfStock !== data) {
      setState((prevInputs) => ({
        ...prevInputs, outOfStock: data,
      }))
    }
    return data
    // } catch (err) {
    //   if (err.response.data.message) {
    //     handleAuthErr(err.response.data.message);
    //     throw new Error(err.response.data.message)
    //   }
    // }
  }

  const incrementQuantity = async (id, sizeID) => {
    // used to roll back if sale failed. happens when there is an error.

    // console.log(id, sizeID)
    axios.put(`${isPath}/buyer/inc/${id}`, { id, sizeID }).then((res) => {
      //  console.log(res.data);
     // console.log(state.products);
      setState((prevInputs) => ({
        ...prevInputs,
        products: prevInputs.products?.items?.map((prod) => (prod._id === id ? res.data : prod)),
      }))
    })
  }

  const createPayment = async (paymentdat) => axios.post('/payment', paymentdat)

  // const retrievePayment = async (paymentdat) => {
  //   //  console.log(orderInfo)
  //   return await axios.post(`/payment/retrieve/`, paymentdat)
  // }
  const createCustomer = async (billing) => axios.post('/payment/create-customer', { billing })

  const createOrder = async (orderInfo, smsdata, emaildata) => {
    // console.log(orderInfo)
    return await axios.post(`${isPath}/createorder`, { orderInfo, smsdata, emaildata })
  }




  // ______________________________
  // const sendSms = async (options) => axios
  //   .post('/sms', options)
  //   .then((res) =>
  //     // console.log('here is the response: ', res);
  //     res)
  //   .catch((err) => {
  //     console.error(err)
  //   })

  // checks if feedback not already given and gets order/seller data
  const buyerShipConfirm = async (stoken) => axios.get(`/feedback/shipconfirm/${stoken}`)

  // Saves confirmation/feedback from the customer.
  const feedbacksave = async (smsid, sellerid, data) => axios.post(`/feedback/feedbacksave/${smsid}`, { sellerid, data })

// SA order confirmation after 14 days.
  const buyerSAConfirm = async (stoken) => axios.get(`/sa/feedback/shipconfirm/${stoken}`)
  const safeedbacksave = async (smsid, sellerid, data) => axios.post(`/sa/feedback/feedbacksave/${smsid}`, { sellerid, data })


  // sends problem feedback
  const sendFeedback = async (stoken, userInfo) => {
    //  console.log(stoken, userInfo)
    try {
      return await axios.post(`/feedback/buyerfeedback/${stoken}`, userInfo)
      // return response
    } catch (err) {
      handleAuthErr(err.response.data.message);
      // return handleAuthErr(err.response.data.message)
    }
    //  return err.response.data.errmsg
  }

  // for notifications
  const getNotifications = async () => {
    // console.log(stoken)
    try {
      //  console.log(state)
      //  handleAuthErr("")
      const { data } = await axios.get(`/api/customerprofile/notifications/${buyer._id}`)
      // console.log(data)
      return data
    } catch (err) {
      console.log(err)
      // console.log(err.response)
      if (err.response.data.message) {
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      } else {
        handleAuthErr('Please try again');
        setTimeout(() => {
          handleAuthErr('')
        }, 4000);
        throw new Error(err.response.data)
      }
    }
  }

  const saveNotifications = async (data) => {
    // console.log(stoken)
    //  console.log(data)
    try {
      //  handleAuthErr("")
      const { res } = await axios.put(`/api/customerprofile/notifications/${buyer._id}`, data)
      // console.log(res)
      return res
    } catch (err) {
      console.log(err)
      // console.log(err.response)
      if (err.response.data.message) {
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      } else {
        handleAuthErr('Please try again');
        setTimeout(() => {
          handleAuthErr('')
        }, 4000);
        throw new Error(err.response.data)
      }
    }
  }

  const getNotificationsProducts = async () => {
    // console.log(stoken)
    // console.log(buyer)
    // if(!buyer) {

    // }
    try {
      //  handleAuthErr("")
      const { data } = await axios.get(`/api/customerprofile/notifications/showproducts/${buyer._id}`)
      // console.log(data)
      return data
    } catch (err) {
      console.log(err)
      // console.log(err.response)
      if (err.response.data.message) {
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      } else {
        handleAuthErr('Please try again');
        setTimeout(() => {
          handleAuthErr('')
        }, 4000);
        throw new Error(err.response.data)
      }
    }
  }

  // for dropdowns
  const returnCatBrandProducts = async () => {
    try {
      if (state.brands && state.typeCategories) {
        return [state.brands, state.typeCategories]
      }
      const promiseArray = [getBrands(), getProductCategories()]
      return await Promise.all(promiseArray)
    } catch (e) {
      console.log(e)
    }
  }

  const getfilterInfo = async () => {
    if (state.filterinfo) {
      return state.filterinfo;
    }
    const response = await axios.get(`${isPath}/buyer/filterinfo`);
    const { data } = response;

    const brandData = data.brands.map((brand) => ({
      value: brand._id,
      label: `${brand._id} (${brand.count})`,
    }));

    // Extract type, seller, and color from the response data
    const { type, seller, color } = data;

    setState((prevInputs) => ({
      ...prevInputs,
      filterinfo: {
        brands: brandData,
        type,
        seller,
        color,
      },
    }));
    // return response.data
  }

  const getBrands = async () => {
    try {
      const brands = await axios.get('/api/categories/brands/')
      if (brands.data) {
        const branddata = brands.data.names.map((category) => ({ value: category, label: category }))

        setState((prevInputs) => ({
          ...prevInputs,
          brands: branddata,
        }))
        return branddata
      }
    } catch (error) {
      console.log(error)
    }
  }
  // sets the product filter 
  const setFilter = (brand) => {
    setState((prevInputs) => ({
      ...prevInputs,
      filter: brand
    }))
  }
  // gets defined and custom brands to use in the filter menu.
  const getBrandsAll = async () => {
    try {
      if (state.brandsAll) {
       // console.log('already got brands', state.brandsAll)
        return state.brandsAll
      } else {
        const brands = await axios.get('/buyer/brandsAll/')
        if (brands.data) {
          // const branddata = brands.data.names.map((category) => ({ value: category, label: category }))

          setState((prevInputs) => ({
            ...prevInputs,
            brandsAll: brands.data,
          }))
          return brands.data
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const setBrandsMapped = (brandA) => {
    setState((prevInputs) => ({
      ...prevInputs,
      brandsMapped: brandA,
    }))
  }

  // ship categories
  const getProductCategories = async () => {
    try {
      const typeCategories = await axios.get('/api/categories/types')
      // console.log(typeCategories)
      if (typeCategories.data) {
        //    console.log(typeCategories.data.property)
        const catdata = typeCategories.data.property.map((category) => ({
          value: category.label, label: category.label, gset: category.hasGender, children: category.children,
        }))
        setState((prevInputs) => ({
          ...prevInputs,
          typeCategories: catdata,
        }))

        return catdata
      }
    } catch (error) {
      console.log(error)
    }
  }

  // return useCallback(() => {
  return {
    getstripe,
    handleAuthErr,
    sendFeedback,
    // buyersmsverify,
    feedbacksave,
    buyerShipConfirm,
    // SA
    buyerSAConfirm,
    safeedbacksave,

    // sendSms,
    createPayment,
    createCustomer,
    createOrder,
    incrementQuantity,
    checkanddecrement,
    checkInStock,
    fetchsellerProducts,
    fetchProduct,
    getBuyerSellers,
    SAProductsFilter,
    SAProductsAndBrands,

    getNotifications,
    saveNotifications,
    returnCatBrandProducts,
    getNotificationsProducts,
    getFeaturedProductsFilter,
    // getBuyerProducts,
    // getBuyerProductsFilter,
    // getBuyerProductsFilter2,
    getfilterInfo,
    setFilter,
    getBrandsAll,
    setBrandsMapped,
    isPath,
    buyer,
    ...state,
  }
  // }, [state, buyer])
}
const useSAShop = () => {
  // Naming changes: sellersSA, buyerSA,
  // const rerenderCount = useRerenderCount();
  // console.log('rendercount: ',rerenderCount)
  const { state, setState, handleAuthErr, getstripe } = useContext(ShopContext);
  const { buyer, isPath, setBuyerInfo } = useLogin()
  // const saPath = isPath ?? null;



  // Saves confirmation/feedback from the customer.
  const feedbacksave = async (smsid, sellerid, data) => axios.post(`/sa/feedback/feedbacksave/${smsid}`, { sellerid, data })

  // sends problem feedback
  const sendFeedback = async (stoken, userInfo) => {
    //  console.log(stoken, userInfo)
    try {
      return await axios.post(`/feedbackSA/buyerSAfeedback/${stoken}`, userInfo)
      // return response
    } catch (err) {
      handleAuthErr(err.response.data.message);
      // return handleAuthErr(err.response.data.message)
    }
    //  return err.response.data.errmsg
  }



  // // for notifications
  // const getNotifications = async () =>
  // {
  //   // console.log(stoken)
  //   try {
  //     //  console.log(state)
  //     //  handleAuthErr("")
  //     const { data } = await axios.get(`/api/customerprofile/notificationsSA/${buyer._id}`)
  //     // console.log(data)
  //     return data
  //   } catch (err) {
  //     console.log(err)
  //     // console.log(err.response)
  //     if (err.response.data.message) {
  //       handleAuthErr(err.response.data.message);
  //       throw new Error(err.response.data.message)
  //     } else {
  //       handleAuthErr('Please try again');
  //       setTimeout(() =>
  //       {
  //         handleAuthErr('')
  //       }, 4000);
  //       throw new Error(err.response.data)
  //     }
  //   }
  // }

  // const saveNotifications = async (data) =>
  // {
  //   // console.log(stoken)
  //   //  console.log(data)
  //   try {
  //     //  handleAuthErr("")
  //     const { res } = await axios.put(`/api/customerprofile/notificationsSA/${buyer._id}`, data)
  //     // console.log(res)
  //     return res
  //   } catch (err) {
  //     console.log(err)
  //     // console.log(err.response)
  //     if (err.response.data.message) {
  //       handleAuthErr(err.response.data.message);
  //       throw new Error(err.response.data.message)
  //     } else {
  //       handleAuthErr('Please try again');
  //       setTimeout(() =>
  //       {
  //         handleAuthErr('')
  //       }, 4000);
  //       throw new Error(err.response.data)
  //     }
  //   }
  // }

  // const getNotificationsProducts = async () =>
  // {
  //   // console.log(stoken)
  //   // console.log(buyerSA)
  //   // if(!buyerSA) {

  //   // }
  //   try {
  //     //  handleAuthErr("")
  //     const { data } = await axios.get(`/api/customerprofile/notificationsSA/showproducts/${buyer._id}`)
  //     // console.log(data)
  //     return data
  //   } catch (err) {
  //     console.log(err)
  //     // console.log(err.response)
  //     if (err.response.data.message) {
  //       handleAuthErr(err.response.data.message);
  //       throw new Error(err.response.data.message)
  //     } else {
  //       handleAuthErr('Please try again');
  //       setTimeout(() =>
  //       {
  //         handleAuthErr('')
  //       }, 4000);
  //       throw new Error(err.response.data)
  //     }
  //   }
  // }
  // for dropdowns


  // return useCallback(() => {
  return {


    sendFeedback,
    feedbacksave,
    getFeaturedProductsFilter,
    // createOrder,
    // incrementQuantity,
    // checkanddecrement,
    // checkInStock,
    // fetchsellerProducts,
    // fetchProduct,
    // getbuyerSASellers,
    // serviceSubscription,
    buyer,
    ...state,
  }
  // }, [state, buyerSA])
}

export { ShopProvider, useShop, useSAShop };

const prod = {
  url: {
    APP_URL: 'https://shop.likeabandit.co',
   
  },
  isLive: true
  // fromTwilio: { messagingServiceSid: "MG880292e2502c363eeeac3d4548bd3332" },
  // fromTwilioBuyer: { messagingServiceSid: "MGaf4a879ca237783f08dbf91f7bee8b31" }
}
const dev = {
  url: {
    APP_URL: process.env.REACT_APP_TL_NGROK || 'https://stark-oasis-17070.herokuapp.com',
  
  },
  isLive: false
  // fromTwilio: { from: "+19096750763" },  // Bandit Shoppers & Store Reps
  // fromTwilioBuyer: { from: "+19096750763" } // orders
}
// const fromTwilio = {
//   ...(process.env.LIVESITE ? { messagingServiceSid: "MG880292e2502c363eeeac3d4548bd3332" } : { from: "+19096750763" })
// }; // Bandit Shoppers & Store Reps

// const fromTwilioBuyer = {
//   ...(process.env.LIVESITE ? { messagingServiceSid: "MGaf4a879ca237783f08dbf91f7bee8b31" } : { from: "+19096750763" })
// }; // orders

 //console.log(process.env);
export const config = process.env.REACT_APP_TL_URL === 'development' ? dev : prod;

import {
  useContext, useState, createContext, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
// import { checkSubscriptionValidity } from '../utils/helpers';
import axios from './customAxios'

import { useCachedValueOnLocationChange } from './useCachedValueOnLocationChange';
// import useRerenderCount from './useRenderCount';
//import useApiPath from './useApiPath'; // Import the custom hook
// const isProd = process.env.REACT_APP_TL_URL !== 'development';
import { config } from '../Constants'
const LoginContext = createContext(null)
const initInputsState = {
  // address_country: "US",
  name: '',
  fullName: '',
  fname: '',
  lname: '',
  address_line1: '',
  address_line2: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  disclaimer: false,
  serviceSubscription: false,
  subscriptionEndDate: null,
  username: '',
  email: '',
  phone: '',
  instagram: '',
  isValidated: false,
}


const LoginProvider = ({ children }) => {
  const isS = localStorage.getItem('seller');
  const loginInfo = isS !== null ? JSON.parse(isS) : null;
  const buyerCache = JSON.parse(localStorage.getItem('buyer')) || initInputsState
  const [state, setState] = useState({
    seller: loginInfo || {},
    buyer: buyerCache,
    serviceSubscription: buyerCache.serviceSubscription,
    token: localStorage.getItem('token') || '',
    name: localStorage.getItem('name') || '',
    username: '',
    email: '',
    phone: '',
    instagram: '',
    sellerav: '',
    isSeller: loginInfo?.isSeller || false,
    isAdmin: loginInfo?.isAdmin || false,
    isSA: loginInfo?.isSA || false,
   // isPath: '', //useCachedValueOnLocationChange(),
    toggle: false,
    authErrMsg: '',
  })

  const value = useMemo(() => ({ state, setState }), [state])
  return (
    <LoginContext.Provider value={value}>
      {children}
    </LoginContext.Provider>
  );
}

const useLogin = () => {
  // const { fetchSubscriptionStatus } = useSubscription();
  //const apiPath = useApiPath(); // Get the API path dynamically
  const { state, setState } = useContext(LoginContext);
const isLive = config.isLive
 // const rerenderCount = useRerenderCount();
 // console.log('rendercount: ', rerenderCount)

  const isPath = useCachedValueOnLocationChange();
  // console.log(isPath);
  const navigate = useNavigate()

  // const setPath = ({isPath}) =>
  // {
  //  // console.log('setting path: ', isPath)
  //   setState((prevInputs) => ({
  //     ...prevInputs, isPath,
  //   }))
  // }
 

  const handleAuthErr = (authErrMsg) =>
  {
    setState((prevInputs) => ({
      ...prevInputs, authErrMsg,
    }))
  }
 
  function setBuyerInfo(buyer, token) {
    const {
      isSeller, isAdmin, fullName, name,
    } = buyer
    // console.log(buyer, token)
    const namespace = fullName || name;
    let buyerNameSplit = {}
    if (!buyer.fname) {
      const namesplit = fullName?.split(' ') || [];
      const [firstName = '', lastName = ''] = namesplit;

      buyerNameSplit = {
        ...buyer,
        fullName,
        name,
        fname:  firstName,
        lname: lastName,
      };
      localStorage.setItem('buyer', JSON.stringify(buyerNameSplit));
    } else {
      localStorage.setItem('buyer', JSON.stringify(buyer));
    }

    //const access = serviceSubscription ?? checkSubscriptionValidity(subscriptionEndDate);
    // const buyerNameSplit = buyer.fna'setByerInfo: ' ...buyer, fullName, name
    // } : { ...buyer, fullName, name, fname: namesplit[0], lname: namesplit[1] };
   // console.log('setByerInfo: ',  buyerNameSplit);

    token && localStorage.setItem('token', token);
   
    localStorage.setItem('name', namespace);
    localStorage.setItem('isSeller', isSeller || false)
    localStorage.setItem('isAdmin', isAdmin || false)
    localStorage.setItem('isSA', 'false')
    localStorage.setItem('serviceSubscription', buyer.serviceSubscription)
    // console.log(namespace);
    setState((prevState) => ({
      ...prevState,
      buyer: buyer.fname ? buyer : buyerNameSplit,
      name: namespace,
      loggedin: true,
      isSeller,
      isAdmin,
      // set the validtiy based on the month. 
      serviceSubscription: buyer.serviceSubscription,
      subscriptionEndDate: buyer.subscriptionEndDate,
      ...(token && { token }),
    }));
    //  console.log(state.token)
  }

  const login = async (credentials) => {
    handleAuthErr('')
    return axios
      .post('/bauth/login', credentials)
      .then((response) => {
        const { buyer, token } = response.data;
        localStorage.removeItem('seller')
        localStorage.removeItem('isSeller')
      //  console.log(buyer);
        setBuyerInfo(buyer, token);
        //  console.log(state,buyer, token, isSeller, isAdmin, fullName, name)
        return response.data
      })
      .catch((err) => {
       console.log(err)
        if (err.response.data.resend === true) {
          handleAuthErr(err.response.data.message);
          return err.response
        }
        if (err.response.data.newuser === true) {
          // user not found need to create a new one
          return err.response.data
        }
        if (err.response.data.reset === true) {
        //  handleAuthErr(err.response.data.message);
          return err.response.data
        }
        
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      })
  }
  // localStorage.getItem('serviceSubscription')
  const logout = () => {
    localStorage.removeItem('carriers')
    localStorage.removeItem('seller')
    localStorage.removeItem('token')
    localStorage.removeItem('buyer')
    localStorage.removeItem('isSeller')
    localStorage.removeItem('isAdmin')
    localStorage.removeItem('isSA')
    localStorage.removeItem('name')
    localStorage.removeItem('serviceSubscription')
    localStorage.removeItem('currentPeriodEnd')

    setState((prevState) => ({
      ...prevState,
      seller: {},
      buyer: {},
      token: '',
      username: '',
      name: '',
      email: '',
      sellerav: '',
      _id: '',
      serviceSubscription: false,
      subscriptionEndDate: false,
      isSeller: false,
      isAdmin: false,
      isSA: false,
     
    }))
    // demo
    const menu = document.getElementById('menu_wrapper')
    menu.classList.toggle('toggled')
    //  this.props.navigate('/');

    navigate('/login')
  }
  const forgot = async (email) => axios
    .post('/bauth/forgot', email)
    .then((response) => {
      const { email } = response.data
      setState((prevInputs) => ({
        ...prevInputs,
        email,
      }))
      return response
    })
  // .catch(err => handleAuthErr(err.response.data.message))

  const reset = (info) => axios
    .post(`/bauth/reset/${info.resettoken}`, info)
    .then((response) => {
      const { password, confirmpassword } = response.data
      if (password === confirmpassword) {
        setState((prevInputs) => ({
          ...prevInputs,
          password,
        }))
      }
      return response
    })
    .catch((err) => {
      throw err.response.data.message;
    })

const getWPTerms = async (page) => {
// get the page content from T&C on the wordpress site.
    try {
      const response = await axios.get(`https://likeabandit.co/wp-json/wp/v2/pages/${page}`)
      return response.data
    } catch (err) {
      console.log(err);
      throw new Error(err);
  }
}
  const getBuyerProfile = async () => {
    try {
      const response = await axios.get('/api/customerprofile')
      return response
    } catch (err) {
      // console.log(err, 'that')
      // in case they had some of this info still but the toke was bad
      //  localStorage.removeItem("land_idsize")
      //  localStorage.removeItem("land_id")
      localStorage.removeItem('seller')
      localStorage.removeItem('token')
      localStorage.removeItem('buyer')
      localStorage.removeItem('isSeller')
      localStorage.removeItem('isAdmin')
      localStorage.removeItem('name')
      setState((prevInputs) => ({
        ...prevInputs,
        seller: {},
        buyer: {},
        token: '',
        name: '',
        email: '',
        instagram: '',
        _id: '',
        isSeller: false,
        isAdmin: false,
        isSA: false,
        toggle: false,
        isLive: '',
      }))
      throw new Error(err.response)
      //  console.log(err)
      // handleAuthErr(err.response.data.message); throw new Error(err.response.data.message)
    }
  }

  const updateBuyerProfile = async (newInfo) => {
    //  console.log(newInfo)
    try {
      const { data } = await axios.put(`/api/customerprofile/${newInfo._id}`, newInfo)
      // console.log(data)
      const { buyer, token } = data
      setBuyerInfo(buyer, token);
    } catch (e) {
     // console.log( e.response.data)
      // err.response.data.message <= return next(new Error(err.message));
      // console.log(err.data, err.response, err.response.message)
      handleAuthErr(e.response.data);
      throw new Error(e.response.data);
    }
  }

  const addWatch = async (id, sizeID, item, size) => { // moved to checkoutform
    //  let soldout = false

    try {
      const { data } = await axios.put(`${isPath}/buyer/addwatch/${id}`, {
        item, sizeID, size, buyer: state.buyer,
      })
      //  console.log(data)
      setBuyerInfo(data);
      return data;
    } catch (e) {
      console.log(e)
    }
  }

  const removeWatch = async (itemId, sizeId, schemaType) => { // moved to checkoutform
    //  let soldout = false
   // console.log(itemId, sizeId, schemaType);
    //   console.log(itemId, sizeId)
    try {
      const { data } = await axios.put(`/api/customerprofile/notifications/product-remove/${state.buyer._id}`, { itemId, sizeId, schemaType })
      //  console.log(data)
      setBuyerInfo(data);

      // localStorage.setItem("buyer", JSON.stringify(data))
      // setState(prevInputs => ({
      //   ...prevInputs,
      //   buyer: {
      //     watching: data.watching
      //   }
      // }))
      // console.log("soldout", data.soldout)
      // setState(prevInputs => ({
      //   ...prevInputs, sizeID: sizeID, outOfStock: data.soldout, sizeName: data.sizeName,
      // }))
      return data;
    } catch (e) {
      console.log(e)
    }
  }

  const buyerSignUp = async (userInfo) => {
    try {
     // return await axios.post('/bauth/signup', userInfo);
      const { data } = await axios.post('/bauth/signup', userInfo);
     // const { buyer, token } = data
    //  setBuyerInfo(buyer, token);
      return data.buyer;
    } catch (err) {
      //  console.log(err)
      handleAuthErr(err.response.data.message); throw new Error(err.response.data.message)
    }
  }
  const validateUsername = async(setError, clearErrors, value) => {
    try {

      // return await axios.post('/bauth/signup', userInfo);
      const { data } = await axios.get(`/bauth/check-username/${value}`);
     // console.log(data)
      if (data.exists) {
        setError('username', { type: 'custom', message: 'Username already exists' });
        return false;
      } else {
         clearErrors('username');
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

 // Buyer stripe subscription stuff
  const getCouponCode = async (couponCode, productId) => {
    try {
      return await axios.post('/subscription/validate-coupon', { couponCode, productId })
      //  return data;

    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };
  const getSubscriptionPlans = async () => {
    try {
      return await axios.get('/subscription/get-subscription-plans')
    //  return data;

    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  }; //get-subscription-plans

 


  const emailConfirmer = async (resettoken) => {
    try {
      const response = await axios.get(`/bauth/emailconf/${resettoken}`)

      if (response) {
        const { buyer, token } = response.data;
        //console.log(buyer, token)
        setBuyerInfo(buyer, token);
        // signup mailchimp.
        //  console.log(response.data)
        // if (isProd) {
        isLive && mailchimpSignup(response.data.mcdata, '')
        //  }
        return buyer
      }
    } catch (err) {
      handleAuthErr(err.response.data.message);
      throw new Error(err.response.data.message)
    }
  }

  const createSubscriptionFromProfile = async (dataSend) => {
    try {
      return await axios.post('/subscription/create-subscription', dataSend);
    } catch (err) {
      console.log(err.response.data);
      throw new Error(err.response.data)
      // handleAuthErr(err.response.data.message);
    }

  }


  const updateSubscriptionStatus = async (subscriptionIdpass, sendinfo) => {
    try {
      const { data: updatedSubscription } = await axios.post(`/subscription/subscription-status/${subscriptionIdpass}`, 
        { updateDB: true, userInfo: sendinfo });
      setState((prevState) => ({
        ...prevState,
        buyer: {
          ...prevState.buyer,
          ...updatedSubscription
        },
        serviceSubscription: updatedSubscription.serviceSubscription
      }));
      localStorage.setItem('buyer', JSON.stringify({
        ...state.buyer, ...updatedSubscription
      }));
   //   console.log({ ...state.buyer, ...updatedSubscription });
      navigate('/direct/subscribe/complete', { replace: true, state: { email: updatedSubscription.email, }});
    } catch (e) {
      console.log(e);
    }
    
    }


  const getSubscriptionPlansgift = async () => {
    try {
      return await axios.get('/subscription/get-subscription-plans-gift')
      //  return data;

    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  }; //get-subscription-plans

  const createGiftpaymentMethod = async (dataSend) => {
    try {
      const res = await axios.post('/subscription/create-giftcard-payment', dataSend);
      return res.data;
    } catch (err) {
      console.log(err.response.data);
      throw new Error(err.response.data)
      // handleAuthErr(err.response.data.message);
    }

  }

  const updateGiftStatus = async (sendinfo) => {
    try {
      const { data: updatedSubscription } = await axios.post('/subscription/create-giftcard/',
        sendinfo);
      //   console.log({ ...state.buyer, ...updatedSubscription });
      navigate('/gift-subscription-complete', { replace: true, state: { email: updatedSubscription.recieveremail, promotionCode: updatedSubscription.promotionCode } });
    } catch (e) {
      console.log(e);
    }

  }

  const getSubscriptionDashboard = async () => {
    try {
   //  console.log(state.buyer);
      const { data } = await axios.get(`/subscription/subscription-status-dash/${state.buyer.customerid}`)
      // console.log(seller, items)
      // console.log(data.subscription)
      if (!state.serviceSubscription) {
      setState((prevInputs) => ({
        ...prevInputs,
        subscriptionStatus: data.subscription,
        // serviceSubscription: Boolean(data.id)
      }));
      }
      return data.subscription

    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  }; //get-subscription-plans
 

  const createportalsession = async (section,) => {

    return await axios.post('/subscription/create-portal-session', {
      customerId: state.buyer.customerid,
      return_url: window.location.href,
      flowType: section, 
      subscriptionId:  state.buyer.subscriptionId,
    });

  } 


 // ext fn

  const mailchimpSignup = (data, route) => axios.post(`/mailchimp/${route}`, data)
    // .then((response) =>
    // // console.log(response)
    //   response)
    // .catch((err) => handleAuthErr(err.response.data.message))

  const confirmresend = async (username) =>
    // console.log(username)
    axios
      .post('/bauth/resendforgot', username)
      .then((response) => {
        const { email } = response.data
        setState((prevState) => ({
          ...prevState,
          email,
        }))
        return response
      })
      .catch((err) => handleAuthErr(err.response.data.message))


  // SELLER STUFF

  const setsellerdata = async (response) => {
    //  console.log(response)
    const { seller, token } = response.data;
    const sellerfull = seller;
    // console.log(sellerfull, token)
    const {
      isSeller, isAdmin, name, _id, username, email, phone, instagram, sellerav, isSA
    } = seller;
    localStorage.removeItem('buyer');
    localStorage.setItem('token', token);
    localStorage.setItem('seller', JSON.stringify(sellerfull));
    localStorage.setItem('isSeller', isSeller);
    localStorage.setItem('isSA', isSA);
    localStorage.setItem('isAdmin', isAdmin);
    localStorage.setItem('name', name);
    const brands = await axios.get('/api/categories/brands/');

    setState({
      ...state,
      _id,
      buyer: {},
      seller: sellerfull,
      token,
      isSeller,
      isAdmin,
      isSA,
      username,
      email,
      phone,
      instagram,
      name,
      sellerav,
      brands: brands.data.names,
    });
  }

  const setSellerProfile = (response) => {
    const {
      name, username, email, phone, instagram, sellerav, _id, isSeller, isAdmin, isSA
    } = response.data;
    setState({
      ...state,
      _id,
      seller: response.data,
      isSeller,
      isAdmin,
      username,
      email,
      phone,
      instagram,
      name,
      sellerav,
      isSA
    });
    return response;
  }

  // seller-admin stuff
  const sellerLinkConfirmer = async (stoken) => {
    // console.log(stoken)
    try {
      handleAuthErr('')
      return await axios.get(`${isPath}/createseller/conf/${stoken}`)
    } catch (err) {
      // console.log(err.response)
      if (err.response.data.message) {
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      } else {
        handleAuthErr('Please try again');
        setTimeout(() => {
          handleAuthErr('')
        }, 4000);
        throw new Error(err.response.data)
      }
    }
  }

 
  const sellerSignUp = async (userInfo) => {
    try {

      const response = await axios.post(isPath + '/auth/signup', userInfo)
      await setsellerdata(response);
      // add to mailchimp
      isLive && mailchimpSignup(response.data.mcdata, 'shopper');
      handleAuthErr('')
      return response
    } catch (err) {
      if (err.response.data.message) {
        handleAuthErr(err.response.data.message);
        throw new Error(err.response.data.message)
      } else {
        handleAuthErr('Please try again');
        throw new Error(err.response.data)
      }
    }
    //  return err.response.data.errmsg
  }
  const sellerSASignUp = async (userInfo) => {
    try {
    
      const response = await axios.post(isPath + '/auth/signup', userInfo)
      await setsellerdata(response);
      // add to mailchimp
      isLive && mailchimpSignup(response.data.mcdata, 'sa')
      //handleAuthErr('')
      return response
    } catch (err) {
      console.log(err.response)
      return err.response
      // if (err.response.message) {
      //  // handleAuthErr(err.response.data.message);
      //   throw new Error(err.response.message)
      // } else {
      // //  handleAuthErr('Please try again');
      //   throw new Error(err.message)
      // }
    }
    //  return err.response.data.errmsg
  }

  const sellerApply = async (userInfo, isSA) => await axios.post(isPath + '/createseller', userInfo)
 
  const sellerForgot = async (email) => axios
    .post('/auth/sellerForgot', email)
    .then((response) => {
      const { email } = response.data
      setState({
        ...state,
        email,
      })
      return response
    })
  // .catch(err => handleAuthErr(err.response.data.message))

  const sellerReset = (info) => axios
    .post(`/auth/sellerReset/${info.resettoken}`, info)
    .then((response) => {
      const { password, confirmpassword } = response.data
      if (password === confirmpassword) {
        setState({
          ...state,
          password,
        })
      }
      return response
    })
    .catch((err) => handleAuthErr(err.response.data.message))

  const sellerLogin = async (credentials) => { 
    try{
     
      const response = await axios.post(isPath + '/auth/login', credentials)
   // console.log(response.data)
      await setsellerdata(response);
      return response.data
    } catch (error) {
      //console.log(error.response.data.error)
      handleAuthErr(error.response.data.message)
    }
  }
  // seller profile
  const getProfile = async () => {
    try {
      const response = await axios.get(`/api${isPath}/profile` )
      // console.log(response.data)
      return setSellerProfile(response);
    } catch (err) {
      console.log(err.response.data.message)
      throw err.response.data.message;
    }
  }
  const updateProfile = async (newInfo) => {
    try {
      const response = await axios
        .put(`/api${isPath}/profile/${state._id}`, newInfo)
      //  const { seller, token } = response.data
      return setSellerProfile(response,);
    } catch (err) {
      // Handle Error Here

      console.error(err);
      handleAuthErr(err.response.data.message)
      throw new Error(err.response.data.message)
      // return err
    }
  }

  const updatesellerlabel = async (newInfo) => {
    setState((prevInputs) => ({
      ...prevInputs,
      seller: { ...state.seller, ...newInfo },
    }));
    localStorage.setItem('seller', JSON.stringify({ ...state.seller, ...newInfo }))
  }

  const updateProfilePhoto = async (newInfo) => axios
    .put(`/api${isPath}/profile/${state._id}/profile-picture/`, newInfo)
    .then((response) => {
      const { sellerav } = response.data
      setState({
        ...state,
        sellerav,
      })
      return response
    })

  // stripe stuff

  const getstripeDashboard = async (accountID) =>
    //  console.log(state.seller.stripeAccountId)
    await axios.get(`/api/sellers/dashboard/${state.seller.stripeAccountId}`)

  const getstripeBalance = async (accountID) =>
    // console.log(state.seller.stripeAccountId)
    await axios.get(`/api/sellers/balance/${state.seller.stripeAccountId}`)

  const getstripeaccountlink = async (id) =>
    // for reonboard
    await axios.get(`/api/sellers/accountlink/${id}`)

  const getstripeloginlink = async () => {
    try {
      return await axios.get(`/api/sellers/express-dashboard-link/${state.seller.stripeAccountId}`);
    } catch (err) {
      console.error(err, err.response.data.message);
      return ''; //  throw new Error(err.response.data.message)
      // return err
    }
  }
  const gopayout = async () => {
    try {
      return await axios.post('/api/sellers/payout');
    } catch (err) {
      console.error(err.response.data);
      throw new Error(err.response.data.message)
      //  return ""; //  throw new Error(err.response.data.message)
      // return err
    }
  }
  const gopayout2 = async () => {
    try {
      return await axios.post('/api/sellers/payout2');
    } catch (err) {
      console.error(err.response.data.message);
      throw new Error(err.response.data.message)
      //  return ""; //  throw new Error(err.response.data.message)
      // return err
    }
  }

  const getOnboard = async () => {
    if (state.seller.stripeAccountId) {
      // console.log(state.seller.stripeAccountId)
      getstripeaccountlink(state.seller.stripeAccountId)
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              window.location = response.data;
            } else {
              //  console.log(response.data)
            }
          }
        })
    } else {
      await axios.post('/onboard/onboard-user', state.seller)
        .then((response) => {
          if (response.status === 200) {
            //   console.log(response.data)
            if (response.data.authuri) {
              setState({
                ...state,
                seller: { ...state.seller, stripeAccountId: response.data.stripeAccountId },
              });
              localStorage.setItem('seller', JSON.stringify({ ...state.seller, stripeAccountId: response.data.stripeAccountId }))
              window.location = response.data.authuri;
            } else {
              // console.log(response.data)
            }
            //   console.log(response.data.authuri)
            // window.location.href = response.data.authuri;
          }
        })
        .catch((error) => {
          //  setStatus("")
          console.log(error.response.data.message);
          throw new Error(error.response.data.message);
        });
    }
  }

  const savesellerstripestatus = async (stripestatus) => {
    try {
      // console.log(state.seller._id, state._id)
      const response = await axios.put(`/api/profile/${state.seller._id}`, { stripe_verify: !!stripestatus })
      // update seller info with stripe account ID
      // console.log(response.data)
      // this clears the stripe id we have set on the client side when begining the onboarding, before redirecting to stripe!
      setState({
        ...state,
        seller: response.data,
      })
      localStorage.setItem('seller', JSON.stringify(response.data))
      // return response.data
    } catch (err) {
      console.error(err);
      throw new Error(err.response.data.message)
      // return err
    }
  }

  // return useMemo(() => {
  return {

    // setPath,
    handleAuthErr,
    login,
    logout,
    forgot,
    reset,

    addWatch,
    removeWatch,
    updateBuyerProfile,
    getBuyerProfile,
    getWPTerms,
    buyerSignUp,
    validateUsername,
    emailConfirmer,
    confirmresend,
    mailchimpSignup,

    // subscription
    createGiftpaymentMethod,
    updateGiftStatus,
    getSubscriptionPlansgift,
    getCouponCode,
    getSubscriptionPlans,
    createportalsession,
    // getSubscriptionStatus,
    getSubscriptionDashboard,
    createSubscriptionFromProfile,
    // createSubscriptionFromSignup,
    updateSubscriptionStatus,

    sellerLinkConfirmer,   
    sellerSignUp,
    sellerSASignUp,
    sellerApply,
    sellerForgot,
    sellerReset,

    sellerLogin,
    getProfile,
    updateProfile,
    updateProfilePhoto,
    updatesellerlabel,

    getstripeDashboard,
    getstripeBalance,
    getstripeaccountlink,
    getstripeloginlink,
    gopayout,
    gopayout2,
    getOnboard,
    savesellerstripestatus,
    setState,
    isPath,
    ...state,
  }
  // }, [state])
}

export { LoginProvider, useLogin }





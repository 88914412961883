import { Suspense, lazy, useEffect, useState } from 'react';
import { Hearts } from 'react-loader-spinner';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SellerReviews from './components/Buyer/SellerReviews';
import { ShopProvider } from './Context/ShopContext';
import { useLogin } from './Context/LoginContext';
import { useSubscriptionStatus, SubscriptionStatusProvider } from './Context/SubscriptionStatusContext';

const Item = lazy(() => import(/* webpackChunkName: 'Checkout' */ './components/Item/Item'));
const SubscriptionManagement = lazy(() => import(/* webpackChunkName: 'Subscribe' */ './components/Buyer/Subscription/SubscriptionManagement'));
//const BuyerProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/BuyerProducts'));
const SaAllProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/Direct/SaAllProducts'));
const SaIndProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/Direct/SaIndProducts'));
// const Notifications = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/Notifications/Notifications'));
const SellerProfile = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/SellerProfile'));
const SubscribeCompletePage = lazy(() => import(/* webpackChunkName: 'Subscribe' */ './components/Buyer/Subscription/SubscribeCompletePage'));
const FeaturedProducts = lazy(() => import(/* webpackChunkName: 'Shop' */ './components/Buyer/FeaturedProducts'));
const LoadingIndicator = () => (
  <div className="overlay">
    <Hearts color="#F05F40" height={100} width={100} />
  </div>
);
const SubscriptionProtectedRoutes = () => {
  const { token, isSA,  isAdmin, buyer } = useLogin();
  const { loading, fetchSubscriptionStatus, isSubscriptionCachedValid } = useSubscriptionStatus();
  const navigate = useNavigate();
  const [isSubscriptionStatusFetched, setIsSubscriptionStatusFetched] = useState(false);

  useEffect(() => {

    if (token && buyer) {
      fetchSubscriptionStatus().then(() => {
        setIsSubscriptionStatusFetched(true);
      });
    } else {
     
      navigate('/')
    }
  }, []);

  if (loading || !isSubscriptionStatusFetched) {
    return <LoadingIndicator />;
  }

  ///console.log(token, isSubscriptionCachedValid,  isSubscriptionValid(), isSA, isAdmin)
  if ((token && isSubscriptionCachedValid) || isSA || isAdmin) {
    //console.log('SAProtectedRoutes reredner', isSubscriptionCachedValid,);
    return (
      <Suspense fallback={<LoadingIndicator />}>
        <Routes>
          <Route index element={<SaAllProducts />} />
            <Route path=":username" element={<SaIndProducts />} />
          <Route path="featured" element={<FeaturedProducts />} />
          <Route path="product/:username/:_id" element={<Item isSAlisting={true} />} />
             <Route path="profile/:username" element={<SellerProfile />} />
            <Route path="profile/:username/review" element={<SellerReviews />} />
            <Route path="/subscription-management" element={<SubscriptionManagement />} />
            <Route path="subscribe/complete" element={<SubscribeCompletePage />} />  
          {/* <Route path="dashboard" element={<SubscriptionManagement />} /> */}
            {/* <Route path="notifications" element={<Notifications />} /> */}
        
        </Routes>
      </Suspense>
    );
  } else {
    return <Navigate to="/shop/subscribe" replace />;
  }
};

export default SubscriptionProtectedRoutes;